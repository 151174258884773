<template lang="pug">
  company-form(v-model="company", :newCompany="true",@submit="createCompany", @cancel="cancel")

</template>
<script>
import CompanyForm from "@/components/forms/CompanyForm.vue";
import CreateCompanyMutation from "@/graphql/mutations/companies/CreateCompanyMutation.gql";
import CompanyQuery from "@/graphql/queries/Company.gql";
export default {
  data() {
    return {
      company: {
        name: null,
        email: null,
        address1: null,
        address2: null,
        address3: null,
        postcode: null,
        isTrial: true,
        trialLength: 30,
        adminName: null,
        adminEmail: null,
        shouldSeed: false,
        saTimezone: null,
      },
    };
  },
  methods: {
    createCompany() {
      const vm = this
      const variables = {
        id: this.$route.params.id,
        searchText: vm.$store.state.searchText,
      };
      return this.$apollo
        .mutate({
          mutation: CreateCompanyMutation,
          variables: {
            input: {
              ...this.company,
            },
          },
          update(store, { data: { createCompany } }) {
            if (createCompany.id) {
              const data = store.readQuery({
                query: CompanyQuery,
                variables,
              });
              data.companies(createCompany);
              store.writeQuery({
                query: CompanyQuery,
                variables,
                data,
              });
            }
          },
        })
        .then(() => {
          this.$flash.success(
            `"${this.company.name}" was successfully created`
          );
          this.$router.push("/companies");
        })
        .catch(() => {
          this.$flash.error("Unable to create the company");
        });
    },
    cancel() {
      this.$router.push("/companies");
    },
  },
  components: {
    CompanyForm,
  },
};
</script>
